import { configureStore } from "@reduxjs/toolkit";
import userConsoleReducer from "@/hooks/userConsolePage";
import getSearchReducer from "@/hooks/getSearch";
import houseMessageReducer from "@/hooks/houseMessage";
import getLikesReducer from "@/hooks/getLikes";

export default configureStore({
  reducer: {
    userConsole: userConsoleReducer,
    search: getSearchReducer,
    houseMessage: houseMessageReducer,
    likes: getLikesReducer,
  },
});

// import { collection, doc, getDocs, onSnapshot } from "firebase/firestore";
// import { db } from "@/firebase/configs";
// import { useEffect, useState } from "react";
import '@/css/global.scss';

const AboutUs = () => {
  // const [document, setDocument] = useState([]);

  // const getAboutUsDoc = async () => {
  //   const docRef = doc(collection(db, "documentation"), "aboutUs");
  //   onSnapshot(docRef, (docSnapShot) => {
  //     let msgHolder = [];
  //     const keys = Object.keys(docSnapShot.data());
  //     keys.forEach((key) => {
  //       msgHolder.push(docSnapShot.data()[key]);
  //     });
  //     console.log(msgHolder);
  //     setDocument(msgHolder);
  //   });
  // };
  // useEffect(() => {
  //   getAboutUsDoc();
  // }, []);

  return (<div className="contentContainer">
    <div className='lineBreaker'>Welcome to HomeHitches, your ally in revolutionizing the real estate experience together. Our mission is to unlock a new era in the real estate market, where property owners are empowered with unmatched control over their transactions. HomeHitches offers a direct pathway for listing properties for sale or rent, creating a streamlined and efficient ecosystem dedicated to simplifying the real estate process.
    </div>
    <div className='lineBreaker'>
    Our platform stands out by allowing you to customize and showcase your listings with ease, facilitating direct connections with prospective buyers or renters. We believe your active engagement is the cornerstone of our innovative journey, making every interaction on HomeHitches meaningful.
    </div>
    <div className='lineBreaker'>
    Dive into the HomeHitches community, a place where seamless collaboration between buyers and sellers promotes transparent and unrestricted communication. Your insights and experiences are invaluable, driving the evolution of property transactions towards a more open and user-centric approach.
    </div>
    <div className='lineBreaker'>
    At HomeHitches, we're not just about listings; we're about building a community where direct interaction is the norm, not the exception. Our commitment to providing a comprehensive listing service at no cost is just the beginning. We invite you to be part of this transformation, where your participation not only enriches our platform but reshapes the real estate landscape itself.
    </div>
    <div className='lineBreaker'>
    Let’s embark on this journey together to make the real estate market more accessible, flexible, and responsive to the needs of today’s buyers and sellers. Experience the difference at HomeHitches, where we pave the way for a smarter, more direct pathway to buying and selling properties. Join us now, and see how your engagement can lead to a revolution in real estate transactions. 
    </div>


   
   
  </div>);
};
export default AboutUs;

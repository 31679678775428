import React, { memo } from "react";
import {ReactComponent as HomeLogo}  from "@/assets/svg/LOGO_HOMEHITCHES.svg";
// import logo from '@/assets/icons/Logo 1.png'
import { HLeftWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clear } from '@/hooks/getSearch';

const HLeft = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToHome = () => {
    dispatch(clear());
    navigate("/home");
    
  }
  return (
    <HLeftWrapper>
      <div className="logo" onClick={goToHome}>
        <HomeLogo onClick={goToHome}/>
        {/* <img className="logoImg" src={logo} alt="logo"/> */}
      </div>
    </HLeftWrapper>
  );
});

export default HLeft;

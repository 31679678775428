import HRight from "./cpn/h-right";
import HLeft from "./cpn/h-left";
import forRentLogo from "@/assets/icons/forrent.png";
import forSaleLogo from "@/assets/icons/forsale.png";
import { toForSale, toForRent } from "@/hooks/houseMessage";
import { search, clear } from '@/hooks/getSearch';
import "./index.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { useNavigate, useLocation  } from "react-router-dom";
import { ConfigProvider, Input } from "antd";

const { Search } = Input;

const HomeHead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [houseFilter, setHouseFilter] = useState("For Rent");
  const { pathname } = useLocation();
  const whetherLogin = useSelector((state) => state.userConsole.value);
  const onSearch = (value) => {
    if(value) {
      const searchArray = value.toLowerCase().split(',');
      dispatch(search(searchArray));
    }
    else {
      dispatch(clear());
    }
  };
  
  let uid = useSelector((state) => state.userConsole.uid);
  const onClick = () => {
    let houseID = uid.slice(0, 6) + Date.now();
    navigate("/house/" + houseID);
  };

  const whetherShow = (pathname) => {
    return (
      !pathname.startsWith("/user") &&
      !pathname.startsWith("/house/") &&
      !pathname.startsWith("/showing/") &&
      !pathname.startsWith("/youmayneedtoknow") &&
      !pathname.startsWith('/aboutUs') 
    );
  };
  const filterState = (houseState) => {
    if (houseState === "For Sale") {
      setHouseFilter(houseState);
      dispatch(toForSale());
    } else {
      dispatch(toForRent());
      setHouseFilter(houseState);
    }
  };
  return (
    <>
      <div className="title">Hitching Freedom, Connect Directly</div>
      <div className="head">
        <HLeft />
        <div className="HconentStyle">
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 20,
              },
            }}
          >
            <Search
              placeholder="Search Address or City"
              onSearch={onSearch}
              className="searchInput"
              allowClear={true}

            />
          </ConfigProvider>
        </div>
        <HRight />
      </div>
      {whetherShow(pathname) && (
        <div className="rent-saleLogo">
          <div className="headerItem1">
            <img
              src={forRentLogo}
              alt="forRentLogo"
              className={houseFilter == "For Sale" ? "img" : "img selected"}
              onClick={() => filterState("For Rent")}
            ></img>
          </div>
          <div className="headerItem2">
            <img
              src={forSaleLogo}
              alt="forSaleLogo"
              className={houseFilter == "For Sale" ? "img selected" : "img"}
              onClick={() => filterState("For Sale")}
            ></img>
          </div>
          <div className="headerItemLast">
            {whetherLogin === "profile" && (
              <Button onClick={onClick}>
                Post Your Property
              </Button>
            )}
          </div>

        </div>
      )}
    </>
  );
};

export default HomeHead;

import React from "react";
import { Button, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { getAuth, signOut } from "@firebase/auth";
import "./styles.scss";
import { doc, setDoc, query, where, onSnapshot, collection, getDoc } from "firebase/firestore";
import { db } from "@/firebase/configs";
import { useEffect, useState, lazy, Suspense } from "react";
import { useForm } from "antd/es/form/Form";

const HouseDisplay = lazy(() => import("@/components/HouseDisplay"));

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const PersonalPage = () => {
  const [page, setPage] = useState("mypost");
  const [houseArray, setHouseArray] = useState([]);
  const [likesArray, setLikesArray] = useState([]);
  const [likesHouseArray, setLikesHouseArray] = useState([]);
  let uid = useSelector((state) => state.userConsole.uid);
  const getAllHouseData = async () => {
    const houseRef = collection(db, "houses");
    let houseQuery = query(houseRef, where("author", "==", uid), where("postStatus", "<", 2));

    onSnapshot(
      houseQuery,
      (houseQuerySnapShot) => {
        let houseQueryArrayHolder = [];
        houseQuerySnapShot.forEach((house) => {
          if (house.data().address) {
            houseQueryArrayHolder.push(house.data());
          }
        });
        setHouseArray(houseQueryArrayHolder);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const onSave = (values) => {
    setDoc(doc(db, "users", uid), values, {
      merge: true,
    }).then(() => {
      window.confirm("Profile updated!");
    });
  };
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
    }).catch((error) => {
      console.log(error);
    });
  }
  const [form] = useForm();
  useEffect(() => {
    let userQuery = query(collection(db, "users"), where("id", "==", uid));
    onSnapshot(
      userQuery,
      (userSnapShot) => {
        userSnapShot.forEach((user) => {
          if (user.data()) {
            form.setFieldsValue(user.data());
            const likesArray = user.get('likesArray');
            setLikesArray(likesArray);
            if (likesArray?.length > 0) {
              const likesHouseQuery = query(collection(db, "houses"), where("id", "in", likesArray),  where("postStatus", "==", 1));
              onSnapshot(
                likesHouseQuery,
                (likesHouseSnapShot) => {
                  let likesHouseArrayHolder = [];
                  likesHouseSnapShot.forEach((house) => {
                    if (house.data().address) {
                      likesHouseArrayHolder.push(house.data());
                    }
                  });
                  setLikesHouseArray(likesHouseArrayHolder);
                }
              )
            }
            else {
              setLikesHouseArray([]);
            }

          }
        });

      },
      (error) => {
        console.log(error);
      }
    );
    getAllHouseData();
  }, [uid]);
  return (
    <div className="personal-page">
      <div className="personal-left">
        <Button className={page == "mypost" ? "profileButton selected" : "profileButton"} onClick={() => setPage("mypost")}>My Posts</Button>
        <Button className={page == "mylikes" ? "profileButton selected" : "profileButton"} onClick={() => setPage("mylikes")}>My Favorites</Button>
        <Button className={page == "myprofile" ? "profileButton selected" : "profileButton"} onClick={() => setPage("myprofile")}>Profile</Button>
        <Button className="profileButton" onClick={handleLogout}>Log Out</Button>
      </div>
      <div className="personal-right">
        {page == "mypost" && (
          <div className="display-houses">
            <Suspense>
              {houseArray?.map((item, index) => {
                if (item.address)
                  return <HouseDisplay key={index} house={item} hideLikes={true} />;
              })}
            </Suspense>
          </div>
        )}
        {page == "mylikes" && (
          <div className="display-houses">
            <Suspense>
              {likesHouseArray?.map((item, index) => {
                if (item.address)
                  return <HouseDisplay key={index} house={item} hideLikes={false} likesArray={likesArray} />;
              })}
            </Suspense>
          </div>
        )}
        {page == "myprofile" && (
          <Form
            {...layout}
            name="nest-messages"
            onFinish={onSave}
            className="display-profile"
            form={form}
          >
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>

            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>

            <Form.Item name="phone" label="Phone Number">
              <Input />
            </Form.Item>

            <Form.Item name="others" label="Other Contacts">
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 8,
              }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
export default PersonalPage;

import React, { useEffect, useState } from "react";
import { Card, Carousel } from "antd";
import { RightOutlined, LeftOutlined, HeartOutlined } from "@ant-design/icons";
import { ReactComponent as Bedroom } from "@/assets/icons/bedroom.svg";
import { ReactComponent as Bathroom } from "@/assets/icons/bathroom.svg";
import { ReactComponent as Parking } from "@/assets/icons/parking.svg";
import "./index.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "@/firebase/configs";
import { ReactComponent as Heart } from "@/assets/icons/heart-thin-icon.svg";
import { ReactComponent as HeartSelect } from "@/assets/icons/red-heart-icon.svg";
import { useSelector } from "react-redux";

const { Meta } = Card;
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
);

const HouseDisplay = ({ house, likesArray, hideLikes }) => {
  const storage = getStorage();
  let location = useLocation();
  const navigate = useNavigate();
  const [like, setLike] = useState(likesArray?.includes(house.id));
  const uid = useSelector((state) => state.userConsole.uid);
  let whetherNavigate = true;

  const handleBeforeChange = (from, to) => {
    //图片变换前判断一下，是否手动点击横线
    if (from !== to) {
      whetherNavigate = false;
    }
  };
  // 每次变换后在把setWhetherNavigate设置成默认;
  const handleAfterChange = () => {
    whetherNavigate = true;
  };
  //在原来的handleClick加上外壳
  const handleCardClick = () => {
    if (whetherNavigate) {
      handleClick();
    }
  };
  const handleClick = () => {
    if (hideLikes) {
      navigate("/house/" + house.id);
    } else {
      navigate("/showing/" + house.id);
    }
  };

  const [autoplay, setAutoplay] = useState(false);
  const [showArrow, setShowArrow] = useState(true);

  const handleMouseEnter = () => {
    setAutoplay(true);
    setShowArrow(true);
  };
  const handleMouseLeave = () => {
    setAutoplay(false);
    setShowArrow(true);
  };
  const handleLike = async () => {
    if (uid) {
      const likeNow = !like;
      let likesArrayNew = likesArray;
      if (likeNow) {
        if (likesArrayNew?.indexOf(house.id) == -1) {
          likesArrayNew.push(house.id);
          await setDoc(doc(db, "users", uid), { likesArray: likesArrayNew }, { merge: true });
        }
        setLike(likeNow);
      } else {
        const index = likesArrayNew.indexOf(house.id);
        if (index > -1) {
          likesArrayNew.splice(index, 1);
          await setDoc(doc(db, "users", uid), { likesArray: likesArrayNew }, { merge: true });
        }
        setLike(false);
      }
    } else {
      navigate("/user");
    }
  }
  useEffect(() => {
    setLike(likesArray?.includes(house.id));
  });

  return (
    <div className={house.postStatus < 1 ? "houseItem shaded":"houseItem"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {!hideLikes && <Heart className={like ? 'like hide' : 'like'} onClick={handleLike} />}
      {!hideLikes && <HeartSelect className={like ? 'like' : 'like hide'} onClick={handleLike} />}
      <Card
        hoverable
        onClick={handleCardClick}
        className="card-container"
        cover={
          <Carousel
            autoplay={autoplay}
            autoplaySpeed={2000}
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
            arrows={showArrow}
            prevArrow={<SlickButtonFix><LeftOutlined /></SlickButtonFix>}
            nextArrow={<SlickButtonFix><RightOutlined /></SlickButtonFix>}
          >
            {house.uploadImage.map((item, index) => (
              <div key={index}>
                <img
                  alt={`Image ${index + 1}`}
                  src={item.thumbUrl}
                  className="card-img"
                />
              </div>
            ))}
          </Carousel>
        }
      >

        <Meta
          title={(house.unit? house.unit + '-' : '')  + house.propertyAddress}
          description={
            house.placeComponents[2]?.short_name +
            "," +
            house.placeComponents[3]?.short_name
          }
        />
        <div className="container">
          <div className="svgContainer">
            {house.bedrooms}
            <Bedroom className="icon" />
          </div>
          <div className="svgContainer">
            {house.bathrooms}
            <Bathroom className="icon" />
          </div>
          <div className="svgContainer">
            {house.outsideParking > 0
              ? (house.garageParking? house.garageParking : 0 )+ " + " + house.outsideParking
              : (house.garageParking? house.garageParking : 0) }
            <Parking className="icon" />
          </div>
        </div>
        <div>${house.price}</div>
      </Card>
    </div>
  );
};
export default HouseDisplay;

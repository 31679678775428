import { Form, message, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./styles.scss";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "@firebase/auth";
import { useDispatch } from "react-redux";
import { register } from "@/hooks/userConsolePage";
import { useState } from "react";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [password, setPassword] = useState(false);
  const auth = getAuth();
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert("User not found!");
        }
        if (error.code === "auth/invalid-credential") {
          alert("Invalid Credential!");
        }
      });
  };
  const goRegister = () => {
    dispatch(register());
  };
  const resetPassword = () => {
    setPassword(true);
  }
  const sendResetEmail = () => {
    const email = form.getFieldValue("email");
    sendPasswordResetEmail(auth, email)
      .then(() => {
        message.success("Sent email to reset password!")
        setPassword(false);
      })
      .catch((error) => {
        //  console.log(error);
      });

  }
  return (
    <div className="login-page">
      <Form
        name="basic"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        className="loginForm"
        autoComplete="yes"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: "grey" }} />}
            placeholder="Email"
          />
        </Form.Item>

        {!password && <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "gray" }} />}
            placeholder="Password"
          />
        </Form.Item>}
        <div className="bottomContainer">
          {!password && <Button className="loginButton" type="primary" htmlType="submit">
            Log in
          </Button>}
          {password &&
            <Button className="loginButton" type="primary" onClick={sendResetEmail}>
              Reset Password
            </Button>
          }

          <div className="registerContent">
            Don't have the account? {" "}
            <span className="sign-in" onClick={goRegister}>
              Click here to create account.
            </span>
          </div>
          {!password && <div className="registerContent">
            Forget Password?{" "}
            <span className="sign-in" onClick={resetPassword}>
              Click here to reset password.
            </span>
          </div>}
          {password && <div className="registerContent">
            <span className="sign-in" onClick={() => setPassword(false)}>
              Click here to go back to login.
            </span>
          </div>}
        </div>

      </Form>
    </div>
  );
};
export default Login;

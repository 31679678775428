const propertyOptions = [
    {
      label: "Balcony",
      value: "Balcony",
    },
    {
      label: "Basement",
      value: "Basement",
    },
    {
      label: "Bike Parking",
      value: "Bike Parking",
    },
    {
      label: "Cable TV",
      value: "Cable TV",
    },
    {
      label: "Ceiling Fan",
      value: "Ceiling Fan",
    },
    {
      label: "Concierge",
      value: "Concierge",
    },
    {
      label: "Dishwasher",
      value: "Dishwasher",
    },
    {
      label: "Doorman",
      value: "Doorman",
    },
    {
      label: "Elevator",
      value: "Elevator",
    },
    {
      label: "Fireplace",
      value: "Fireplace",
    },
    {
      label: "Fitness Center",
      value: "Fitness Center",
    },
    {
      label: "Garbage Disposal",
      value: "Garbage Disposal",
    },
    {
      label: "Microwave",
      value: "Microwave",
    },
    {
      label: "Online Application",
      value: "Online Application",
    },
    {
      label: "Oven",
      value: "Oven",
    },
    {
      label: "Package Service",
      value: "Package Service",
    },
    {
      label: "Patio",
      value: "Patio",
    },
    {
      label: "Pet Park",
      value: "Pet Park",
    },
    {
      label: "Refrigerator",
      value: "Refrigerator",
    },
    {
      label: "Resident Lounge",
      value: "Resident Lounge",
    },
    {
      label: "Roof Deck",
      value: "Roof Deck",
    },
    {
      label: "Secured Entry",
      value: "SecuredEntry",
    },
    {
      label: "Storage",
      value: "Storage",
    },
    {
      label: "Swimming Pool",
      value: "Swimming Pool",
    },
    {
      label: "Walk-in Closet",
      value: "Walk-in Closet",
    },
    {
      label: "Wheelchair Access",
      value: "Wheelchair Access",
    },
  ];
  export default propertyOptions
import { footerMsg } from "@/const/app-footer";
import "./styles.scss";
const HomeFooter = () => {
  return (
    <div>
      <div className="footer-top">
        {footerMsg.map((item, index) => {
          return (
            <div key={index} className="item">
              {item.list.map((itemd, indexd) => {
                return (
                  <div key={indexd} className="names">
                    <a target="_blank" className="names" href={itemd.url}>{itemd.name}</a>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="lowest">
        <div>© 2023 by HomeHitches</div>
      </div>
    </div>
  );
};

export default HomeFooter;

import { useSelector } from "react-redux";
import { collection, getDocs, query, where, limit, orderBy, startAfter, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/configs";
import { useEffect, useState } from "react";
import React from "react";
import "./style.scss";
import InfiniteScroll from 'react-infinite-scroll-component';
import HouseDisplay from "@/components/HouseDisplay";

const HomeContent = (props) => {
  //根据这个来判断用户要查看什么类型的房子
  const filterState = useSelector(state => state.houseMessage.houseState);
  const searchText = useSelector(state => state.search.text);
  //存的就是数据库里所有的房子信息，直接用这个遍历
  const [saleHouseArray, setSaleHouseArray] = useState([]);
  const [rentHouseArray, setRentHouseArray] = useState([]);
  // const [location, setLocation] = useState(null);
  const [hasMoreSale, setHasMoreSale] = useState(true);
  const [hasMoreRent, setHasMoreRent] = useState(true);
  const [lastVisibleRent, setLastVisibleRent] = useState(undefined);
  const [lastVisibleSale, setLastVisibleSale] = useState(undefined);

  // const getLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setLocation({
  //       longitude: position.coords.longitude,
  //       latitude: position.coords.latitude,
  //     });
  //   });
  //   console.log("location", location);
  // };
  const getQuery = (lastVisible, searchText, filterState) => {
    const houseRef = collection(db, "houses");
    if (lastVisible) {

      if (searchText) {
        return query(houseRef, where("homeState", "==", filterState), where("postStatus", "==", 1), where("searchTermsArray", 'array-contains-any', searchText), orderBy("updateAt", "desc"), startAfter(lastVisible), limit(4));
      }
      else {
        return query(houseRef, where("homeState", "==", filterState), where("postStatus", "==", 1), orderBy("updateAt", "desc"), startAfter(lastVisible), limit(4));
      }
    }
    else {
      if (searchText) {
        return query(houseRef, where("homeState", "==", filterState), where("postStatus", "==", 1), where("searchTermsArray", 'array-contains-any', searchText), orderBy("updateAt", "desc"), limit(8));
      }
      else {
        return query(houseRef, where("homeState", "==", filterState), where("postStatus", "==", 1), orderBy("updateAt", "desc"), limit(8));
      }
    }

  }
  const getAllHouseData = async () => {
    if (searchText || saleHouseArray.length == 0 || rentHouseArray.length == 0) {
      let houseQuery = {};
      let houseSnap = {};
      if (filterState === 'For Sale' && hasMoreSale) {
        try {
          houseQuery = getQuery(lastVisibleSale, searchText, filterState);
          houseSnap = await getDocs(houseQuery);
            setSaleHouseArray(
              houseSnap.docs.map((house) => {
                if (house.data().address) return house.data();
              })
            );
        } finally {
          if (houseSnap.docs.length == 8) {
            setLastVisibleSale(houseSnap.docs[houseSnap.docs.length - 1]);
          }

        }
      }
      else if (hasMoreRent) {
        try {
          houseQuery = getQuery(lastVisibleRent, searchText, filterState);
          houseSnap = await getDocs(houseQuery);
          setRentHouseArray(
            houseSnap.docs.map((house) => {
              if (house.data().address) return house.data();
            })
          );
        } finally {
          if (houseSnap.docs.length > 0)
            setLastVisibleRent(houseSnap.docs[houseSnap.docs.length - 1]);
        }
      }
    }
  };
  const loadFunc = async () => {
    let houseQuery = {};
    let houseSnap = {};
    if (filterState === 'For Sale' && hasMoreSale) {
      if (lastVisibleSale) {
        try {
          houseQuery = getQuery(lastVisibleSale, searchText, filterState);
          houseSnap = await getDocs(houseQuery);
          const saleHouseArrayHolder = saleHouseArray;
          if (houseSnap.docs.length > 0) {
            houseSnap.docs.map(house => {
              if (house.data().address)
                saleHouseArrayHolder.push(house.data());
            })
            setSaleHouseArray(saleHouseArrayHolder);
          }
          else {
            setHasMoreSale(false);
          }
        } finally {
          setLastVisibleSale(houseSnap.docs[houseSnap.docs.length - 1]);
        }
      }
      else {
        getAllHouseData();
      }
    } else if (filterState === 'For Rent' && hasMoreRent) {
      if (lastVisibleRent) {
        try {
          houseQuery = getQuery(lastVisibleRent, searchText, filterState);
          houseSnap = await getDocs(houseQuery);
          const rentHouseArrayHolder = rentHouseArray;
          if (houseSnap.docs.length > 0) {
            houseSnap.docs.map(house => {
              if (house.data().address)
                rentHouseArrayHolder.push(house.data());
            })
            setRentHouseArray(rentHouseArrayHolder);
          }
          else {
            setHasMoreRent(false);
          }
        } finally {
          setLastVisibleRent(houseSnap.docs[houseSnap.docs.length - 1]);
        }
      }
      else {
        getAllHouseData();
      }
    }
  }

  useEffect(() => {
    getAllHouseData();
    // getLocation();
    // if (location) {
    //   localStorage.setItem("userLocation", JSON.stringify(location));
    // }
  }, [filterState, searchText]);
  return (

    <div >
      {filterState === "For Sale" &&
        <InfiniteScroll
          dataLength={filterState === 'For Sale' ? saleHouseArray.length : rentHouseArray.length}
          next={loadFunc}
          hasMore={hasMoreSale}
          className="displayContainer"
          height={window.innerHeight - 230}
        >
          {saleHouseArray?.map((item, index) => {
            if (item.address) return <HouseDisplay key={index} house={item} likesArray={props.likesArray} />;
          })}
        </InfiniteScroll>
      }
      {filterState === "For Rent" &&
        <InfiniteScroll
          dataLength={filterState === 'For Sale' ? saleHouseArray.length : rentHouseArray.length}
          next={loadFunc}
          hasMore={hasMoreRent}
          className="displayContainer"
          height={window.innerHeight - 230}
        >
          {rentHouseArray?.map((item, index) => {
            if (item.address) return <HouseDisplay key={index} house={item} likesArray={props.likesArray} />;
          })}
        </InfiniteScroll>
      }


    </div>
  );
}

export default HomeContent;

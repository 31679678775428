import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import HomeFooter from "./components/home-footer";
import HomeHead from "./components/home-head";
import HomeContent from "./components/home-content";
import UserConsole from "./components/UserConsole";
import AboutUs from "./components/AboutUs";
import Instruction from "./components/Instruction";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login, profile } from "@/hooks/userConsolePage";
import Uploader from "./components/Uploader";
import CondoShower from "./components/CondoShower";
import { Modal, Tabs } from 'antd';
import { SellerSteps, BuyerSteps, LandlordSteps, TenantSteps } from "./components/staticPages";
import { setDoc, doc, query, collection, where, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/configs";

function App() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uid, setUid] = useState();
  const [likesArray, setLikesArray] = useState([]);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    const data = { dontShowAgain: true };
    setDoc(doc(db, "users", uid), data, { merge: true });
  };
  const auth = getAuth();
  useEffect(() => {
    if (auth.currentUser) {
      dispatch(profile(auth.currentUser.uid));

    } else {
      dispatch(login());
    }
    onAuthStateChanged(auth, async () => {
      if (auth.currentUser?.uid) {
        dispatch(profile(auth.currentUser.uid));
        setUid(auth.currentUser.uid);
        let userQuery = query(collection(db, "users"), where("id", "==", auth.currentUser.uid));
        onSnapshot(
          userQuery,
          (userSnapShot) => {
            userSnapShot.forEach((user) => {
              if (user.data()) {
                if (!user.get('dontShowAgain')) {
                  setIsModalOpen(true);
                }
                setLikesArray(user.get('likesArray') ? user.get('likesArray') : []);
              }
            });
          })
      } else {
        dispatch(login());
      }
    });
  }, [auth, dispatch]);

  const items = [
    {
      key: '1',
      label: 'Seller',
      children: <SellerSteps />
    },
    {
      key: '2',
      label: 'Buyer',
      children: <BuyerSteps />
    },
    {
      key: '3',
      label: 'Landlord',
      children: <LandlordSteps />
    },
    {
      key: '4',
      label: 'Tenant',
      children: <TenantSteps />,
    },
  ]
  return (
    <div className="App">
      <HomeHead />
      <Modal title="Hitch Manual" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} cancelText="Don't show it again">
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
      <Routes>
        <Route exact path="/" element={<HomeContent />} />
        <Route exact path="/home" element={<HomeContent likesArray={likesArray} />} />
        <Route exact path="/user" element={<UserConsole />} />
        <Route exact path="/aboutUs" element={<AboutUs />} />
        {/* <Route exact path="/qa" element={<QueAndAns />} /> */}
        <Route exact path="/youmayneedtoknow" element={<Instruction />} />
        <Route path="/house/:houseID" element={<Uploader />} />
        <Route path="/showing/:houseID" element={<CondoShower />} />
        <Route path="*" element={<HomeContent />} />
      </Routes>
      <HomeFooter />
    </div>
  );
}

export default App;

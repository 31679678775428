import { Button, ConfigProvider } from "antd";
import { RightStyles } from "./style";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

const HRight = () => {
  const navigate = useNavigate();
  const whetherLogin = useSelector((state) => state.userConsole.value);
  const onLoginClick = () => {
    navigate("/user");
  };
  const onAboutUsClick = () => {
    navigate("/aboutUs");
  };
  const onQueAndAnsClick = () => {
    navigate("/qa");
  };
  const onInstructionsClick = () => {
    navigate("/youmayneedtoknow");
  };
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token，影响范围大
            colorPrimary: "black",
            borderRadius: 20,
            primaryShadow: "5px",
          },
        }}
      >
        <RightStyles>
          <ul>
            <li>
              <Button className="pointer" onClick={onAboutUsClick}>About Us</Button>
            </li>
            {/* <li>
              <Button className="pointer" onClick={onQueAndAnsClick}>Q&A</Button>
            </li> */}
            <li>
              <Button className="pointer" onClick={onInstructionsClick}>You May Need To Know</Button>
            </li>
            <li>
              <Button className="btn" onClick={onLoginClick}>
                {whetherLogin !== "profile" && "Login"}
                {whetherLogin === "profile" && <UserOutlined />}
              </Button>
            </li>
          </ul>
        </RightStyles>
      </ConfigProvider>
    </>
  );
};

export default HRight;

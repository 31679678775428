import '@/css/global.scss';
const SellerDetails = () => {
  return (<div>
    <p>Selling a property involves navigating through several key steps to ensure a smooth and successful transaction. This guide is designed to walk you through the process, highlighting legal requirements and practical considerations. Whether you&apos;re a first-time seller or have been through the process before, following these steps can help facilitate a seamless sale.</p>
    <p><strong>A Complete Guide for Property Sellers&nbsp;</strong></p>
    <p>1. <strong>Preparation and Valuation</strong>:</p>
    <ul>
      <li><strong>Professional Appraisal</strong>: Obtain to set a competitive price. Example: A local appraiser evaluates based on recent neighborhood sales.<ul>
        <li>Address repairs to improve value.</li>
        <li>Declutter and clean for a spacious look. Example: Using storage solutions.</li>
        <li>Stage your home to highlight its potential. Example: Rearrange furniture for an open feel.</li>
        <li>Secure professional photos for compelling listings.</li>
      </ul>
      </li>
    </ul>
    <p>2. <strong>Legal Preparation</strong>:</p>
    <ul>
      <li><strong>Hire a Real Estate Lawyer</strong>: Essential for document preparation, title searches, compliance assurance, and closing processes. Example: Lawyer identifies and resolves an old easement issue.</li>
      <li><strong>Budgeting for Lawyer Fees</strong>: Include initial consultations and transaction oversight.</li>
    </ul>
    <p>3. <strong>Listing and Marketing with HomeHitches</strong>:</p>
    <ul>
      <li><strong>List on HomeHitches</strong>: For efficient, direct buyer-seller communication.</li>
      <li><strong>Marketing and Advertising</strong>: Allocate funds for enhanced online and offline property promotion. Remember, HomeHitches is alway free.</li>
    </ul>
    <p>4. <strong>Showings, Offers, and Negotiations</strong>:</p>
    <ul>
      <li><strong>Prepare for Showings</strong>: Maintain readiness for visits.</li>
      <li><strong>Negotiate Offers</strong>: Decide on direct negotiation or through your lawyer, accounting for potential additional legal fees. Example: Negotiating a below-asking price offer by including high-demand appliances in the deal.</li>
      <li><strong>Understand Offer Contingencies</strong>: Be aware of common conditions like financing or inspection contingencies that might affect the sale.</li>
    </ul>
    <p>5. <strong>Closing the Sale</strong>:</p>
    <ul>
      <li><strong>Closing Costs</strong>: Budget for legal fees, mortgage discharge fees (if applicable), and adjustments for taxes and utilities.</li>
      <li><strong>Home Inspection</strong>: Opt for a pre-listing inspection to address issues beforehand. Allocate a budget for any necessary repairs.</li>
      <li><strong>Finalizing the Transaction</strong>: Ensure all legal and financial aspects are covered during closing.</li>
      <li><strong>Moving and Transition Costs</strong>: Plan for moving expenses, including hiring movers and managing utilities overlap.</li>
    </ul>
    <p></p>
    <p>Consulting with a real estate lawyer and a tax advisor is crucial to understand the specific legal and tax implications of selling property. This tailored advice can be invaluable in navigating the complexities of the real estate market.This guide aims to equip you with the knowledge and steps necessary for a successful property sale, ensuring you are prepared for each stage of the process.</p>

  </div>


  );
}
const BuyerDetails = () => {
  return (<div>
<p>Buying a property involves navigating through several key steps to ensure a smooth and successful transaction. This guide is designed to walk you through the process, highlighting legal requirements and practical considerations. Whether you&apos;re a first-time seller or have been through the process before, following these steps can help facilitate a seamless transaction.</p>
<p><strong>A Comprehensive Guide for Property Buyers</strong></p>
<p>1. <strong>Financial Preparation</strong>:</p>
<ul>
    <ul>
        <li><strong>Action</strong>: Obtain mortgage pre-approval to understand your budget limits.</li>
        <li><strong>Example</strong>: Consulting a mortgage broker for an in-depth analysis of your financial situation helps determine how much you can afford.</li>
        <li><strong>Considerations</strong>: Factor in closing costs (1.5% to 4% of purchase price), home inspection fees (approximately $300 to $500), and moving expenses.</li>
        <li><strong>Tip</strong>: Start a savings plan early to cover these expenses, ensuring they don&rsquo;t detract from your down payment resources.</li>
    </ul>
</ul>
<p>2. <strong>Property Search and Evaluation</strong>:</p>
<ul>
    <ul>
        <li><strong>Strategy</strong>: Create a checklist to separate essential features (location, number of bedrooms) from desired features (finished basement, backyard).</li>
        <li><strong>Use HomeHitches for Direct Listings Access</strong>:<strong>&nbsp;</strong>Offers straightforward access to property listings and the ability to directly book showings.</li>
        <li><strong>Selection Tip</strong>: Act promptly to book showings for properties that meet your criteria, demonstrating a proactive approach in your preferred neighborhoods.</li>
    </ul>
</ul>
<p><strong>3. Legal Preparation</strong>:</p>
<ul>
    <li><strong>Hire a Real Estate Lawyer</strong>: Essential for document preparation, title searches, compliance assurance, and closing processes. Example: Lawyer identifies and resolves an old easement issue.</li>
    <li><strong>Budgeting for Lawyer Fees</strong>: Include initial consultations and transaction oversight.</li>
</ul>
<p><strong>4</strong>. <strong>Making an Offer</strong>:</p>
<ul>
    <ul>
        <li><strong>Research</strong>: Investigate comparable sales in the area to gauge an appropriate offer, keeping in mind seasonal market trends.</li>
        <li><strong>Consult with Your Lawyer</strong>: They ensure your offer includes essential contingencies for financing and inspection, protecting your interests and deposit.</li>
        <li><strong>Recommendation</strong>: Utilize a lawyer&rsquo;s expertise to navigate the offer process, especially for adding conditions that safeguard your investment. Or you can choose to do it yourself.</li>
    </ul>
</ul>
<p><strong>5</strong>. <strong>Home Inspection and Due Diligence</strong>:</p>
<ul>
    <ul>
        <li><strong>Schedule a Home Inspection</strong>: A critical step to uncover structural, pest, or system issues (electrical, plumbing) potentially affecting your decision.</li>
        <li><strong>Remind your Lawyer</strong> <strong>Review All Documentation</strong>: Request property tax receipts, utility bills, renovation permits, and warranties on recent work to avoid any future surprises.</li>
    </ul>
</ul>
<p>6. <strong>Closing the Transaction</strong>:</p>
<ul>
    <ul>
        <li><strong>Final Steps</strong>: Verify mortgage details, perform a final walkthrough to check the completion of agreed repairs, and organize the payment balance with your lawyer, including down payment and closing costs.</li>
        <li><strong>Detailed Budgeting</strong>: Account for land transfer taxes, lawyer&apos;s fees, title insurance, and adjustments for any prepaid utilities or property taxes by the seller.</li>
    </ul>
</ul>
<p><strong>Additional Insights for First-Time Buyers:</strong></p>
<ul>
    <li><strong>Mortgage Rate Shopping</strong>: Ensure you&rsquo;re getting the best deal by comparing rates from several lenders.</li>
    <li><strong>First-Time Buyer Incentives</strong>: Explore federal and provincial programs designed to assist first-time buyers, like the First-Time Home Buyer Incentive or using the RRSP Home Buyers&apos; Plan.</li>
    <li><strong>Long-Term Affordability</strong>: Include future expenses such as property taxes, homeowners&apos; insurance, and ongoing maintenance in your financial planning.</li>
</ul>
<p>Consulting with a real estate lawyer and a tax advisor is crucial to understand the specific legal and tax implications of selling property. This tailored advice can be invaluable in navigating the complexities of the real estate market.This guide aims to equip you with the knowledge and steps necessary for a successful property sale, ensuring you are prepared for each stage of the process.</p>

  </div>);
}
const LandlordDetails = () => {
  return (<div>

<p>This guide is designed to assist tenants in understanding and navigating the rental process from start to finish. It provides detailed steps, practical tips, and essential information to ensure a smooth transition into your new rental home.</p>
<p><strong>Preparation and Letting Readiness:</strong></p>
<p>1. Rental Assessment: Determine a competitive rental price by comparing with local listings. </p><p>2. Repairs: Address any necessary repairs to ensure the property is safe and appealing. </p><p>3. Cleaning and Decluttering: Make your property more attractive by cleaning thoroughly and using storage solutions to maximize space. </p><p>4. Photography: Capture your property in its best light with professional photographs. </p><p>5. Tenant Communication: If your property is currently occupied, coordinate with existing tenants for smooth showings.</p>
<p><strong>Legal and Regulatory Preparation:</strong></p>
<p>1. Understand Local Laws: Familiarize yourself with local rental regulations to set proper lease terms and comply with legal standards, including notice periods for termination and rent increases. </p><p>2. Property Standards: Ensure your property meets safety codes and living standards, such as installing smoke detectors. </p><p>3. Tenant Screening: Implement a comprehensive screening process, including background and credit checks, to find reliable tenants. </p><p>4. Budget for Compliance: Anticipate costs associated with legal advice, obtaining safety certificates, and ensuring compliance.</p>
<p><strong>Listing and Marketing on HomeHitches</strong></p>
<ol>
    <li>List Your Property: Utilize HomeHitches for effective marketing and streamlined landlord-tenant communication.&nbsp;</li>
    <li>HomeHitches is a free service designed to be efficient and free of conflicts of interest.</li>
</ol>
<p><strong>Showings, Applications, and Lease Negotiations</strong></p>
<p>1. Prepare for Showings: Keep your property in top condition to impress potential tenants. </p><p>2. Review Applications: Conduct a thorough review process, including reference and credit checks, and, if possible, a face-to-face interview. </p><p>3. Negotiate Lease Terms: Clearly define rent, security deposits (as permitted by law), and lease duration, ensuring you understand tenant rights and seek legal advice if necessary.</p>
<p><strong>Finalizing the Lease</strong></p>
<p>1. Collect Payments: Secure the first and last month&apos;s rent in accordance with local laws and agreed-upon terms. </p><p>2. Complete the Lease Agreement: Ensure all terms and responsibilities are clearly outlined and understood. </p><p>3. Property Handover: Document the property&apos;s condition and provide keys or access codes, recommending proof of tenant insurance and hydro registration before key handover.</p>
  </div>);
}
const TenantDetails = () => {
  return (<div>

<p><strong>A Comprehensive Guide for Tenants</strong></p>
<p>This guide is designed to assist tenants in understanding and navigating the rental process from start to finish. It provides detailed steps, practical tips, and essential information to ensure a smooth transition into your new rental home.</p>
<p><strong>Preparation and Letting Readiness</strong></p>
<p>- Set up your rental budget, consider your ideal location, and decide on a realistic move-in date.</p>
<p>- Start your search early to have ample time to find the right place.</p>
<p><strong>Legal and Regulatory Preparation</strong></p>
<p>- Familiarize yourself with local rental laws, focusing on lease termination, rent increases, and tenant rights.</p>
<p>- Ensure the rental property meets all safety codes and living standards, prioritizing your well-being.</p>
<p>- Research online resources for local rental regulations and tenant advocacy groups for support.</p>
<p><strong>Contact with the Landlord on HomeHitches</strong></p>
<p>- Use HomeHitches to find listings with comprehensive information and clear communication channels.</p>
<p>- Prepare a list of questions about the lease terms, property maintenance, and any fees or deposits.</p>
<p><strong>Showings, Applications, and Lease Negotiations</strong></p>
<p>- Make a good impression during viewings; be punctual, dressed appropriately, and prepared with questions.</p>
<p>- Understand all lease terms before signing. If unsure, consider consulting with a legal advisor.</p>
<p>- Negotiate lease terms diplomatically, focusing on win-win outcomes.</p>
<p><strong>Finalizing the Lease</strong></p>
<p>- Conduct a detailed walk-through of the property to document its condition. Use this documentation to avoid future disputes.</p>
<p>- Finalize utility registrations, secure tenant insurance, and ensure compliance with any other requirements before moving in.</p>
<p><strong>Additional Tips</strong></p>
<p>- Keep a record of all communications with your landlord.</p>
<p>- Ensure accessibility needs are addressed, either through physical accommodations or other necessary arrangements.</p>
  </div>);
}


export { SellerDetails, BuyerDetails, LandlordDetails, TenantDetails }
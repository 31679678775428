import { createSlice } from '@reduxjs/toolkit'

export const getLikes= createSlice({
  name: 'likes',
  initialState: {
    value: 'likes'
  },
  reducers: {
    likes: (state, action) => {
      state.value = "likes";
      state.likesArray = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { likes } = getLikes.actions

export default getLikes.reducer
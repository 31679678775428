import React from "react";
import { Button, Cascader, Checkbox, Form, Input, Select } from "antd";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from '../../../firebase/configs'
import "./styles.scss";
import { useDispatch } from 'react-redux'
import { login } from '@/hooks/userConsolePage';


const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const Register = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const goLogin = () => {
    dispatch(login());
  };
  const auth = getAuth();
  const onRegisterPress = (values) => {
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (response) => {
        const uid = response.user.uid;
        if(!values.phone) {
          values.phone = null;
        }
        if(!values.others) {
          values.others= null;
        }
        const data = {
          id: uid,
          ...values,
        };
        delete data.password;
        delete data.confirm;
        try {
          const docRef = await setDoc(doc(db, "users", uid), data).then(() => {
            signInWithEmailAndPassword(
              auth,
              values.email,
              values.password
            ).then(() => {
              navigate("/home");
            });
          });
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      })
      .catch((error) => {
        alert("The Email is already created account!");
      });
  };

  return (
    <div className="register-page ">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onRegisterPress}
        style={{
          width: 450,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Your Name"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              min: 6,
              message: "Password must be at least 6 characters long",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: false,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="others"
          label="Other Contacts"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>

        <Form.Item style={{ width: "500px", marginLeft: "120px" }}>
          <div
            style={{
              color: "gray",
              cursor: "pointer",
              transition: "color 0.3s",
            }}
          >
            I have already had an account!
            <span className="log-in" onClick={goLogin}>
              Click here to Log in.
            </span>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Register;

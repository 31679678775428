import { Button, Form, Select, Input, Checkbox, Modal, Upload, message, DatePicker, Popconfirm} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, app } from "@/firebase/configs";
import { getAuth } from "@firebase/auth";
import { useState, useEffect, useRef } from "react";
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import propertyOptions from "@/const/const";
import "./style.scss";
import dayjs from "dayjs";
import { useNavigate, redirect } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

const Uploader = () => {
  let { houseID } = useParams();
  const auth = getAuth();
  const [form] = useForm();
  const storage = getStorage();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [address, setAddress] = useState();
  const [placeComponents, setPlaceComponents] = useState();
  const [propertyAddress, setPropertyAddress] = useState();
  const [processionDate, setProcessionDate] = useState();
  const [postStatus, setPostStatus] = useState(0);

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: ["ca", "us"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };
  const navigate = useNavigate();
  const Goto = url => {
    navigate(url);
  }
  const BackHome = () => {
    navigate("/home");
  }


  const getHouseData = async (houseID) => {
    const houseRef = doc(db, "houses", houseID);
    const houseSnap = await getDoc(houseRef);
    if (houseSnap.data()) {
      if (houseSnap.data().author != auth.currentUser.uid) {
        Goto("/showing/" + houseID);
      }
      form.setFieldsValue(houseSnap.data());
      setFileList(houseSnap.data().uploadImage);
      if (inputRef.current)
        inputRef.current.value = houseSnap.data().address ?? "";
      setAddress(houseSnap.data().address);
      setPlaceComponents(houseSnap.data().placeComponents);
      setPropertyAddress(houseSnap.data().propertyAddress);
      setProcessionDate(houseSnap.data().processionDate);
    }
    else {
      const convertedValues = {
        id: houseID,
        author: auth.currentUser.uid,
        createAt: Date.now(),
        postStatus: 0,
      };
      setDoc(doc(db, "houses", houseID), convertedValues);
    }
  };
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  useEffect(() => {
    getHouseData(houseID);
  }, [houseID]);
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      setAddress(inputRef.current.value);
      setPropertyAddress(inputRef.current.value);
      if (place.address_components) {
        setPlaceComponents(place.address_components);
        setPropertyAddress(
          place.address_components[0].short_name +
          " " +
          place.address_components[1].short_name
        );
      }
    });
  }, [autoCompleteRef]);

  const onFinish = (values) => {
    let newFileList = fileList;
    newFileList.map(async (file) => {
      if (file.originFileObj) {
        file.thumbUrl = "https://firebasestorage.googleapis.com/v0/b/"+app.options.storageBucket + "/o/HousePictures%2F" + houseID + "%2F" + file.originFileObj.uid + "_600x400?alt=media"
      }
      delete file.originFileObj;
      delete file.xhr;
    });
    let searchTermsArray = [propertyAddress.toLowerCase(), placeComponents[1].short_name.toLowerCase(), placeComponents[1].long_name.toLowerCase(), placeComponents[3].short_name.toLowerCase()];
    const convertedValues = {
      ...values,
      uploadImage: newFileList,
      id: houseID,
      author: auth.currentUser.uid,
      address: address,
      propertyAddress: propertyAddress,
      placeComponents: placeComponents,
      searchTermsArray: searchTermsArray,
      updateAt: Date.now(),
      processionDate: processionDate,
      postStatus: postStatus
    };

    // 把undefined变成null
    for (const key in convertedValues) {
      if (
        convertedValues.hasOwnProperty(key) &&
        convertedValues[key] === undefined
      ) {
        delete convertedValues[key];
      }
    }
    setDoc(doc(db, "houses", houseID), convertedValues, {
      merge: true,
    }).then(() => {
      if(postStatus==0) { 
      message.success("Save successfully!");
      }
      if(postStatus == 1) {
        message.success("Post successfully!");
      }
    });
  };

  // 以下是上传图片会用到的常量和方法

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = (file) => {
    setPreviewImage(file.response);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let isReady = true;
    const uploadImage = [];
    newFileList.map((file) => {
      console.log(file);
      if (file.response) {
        uploadImage.push({
          uid: file.uid,
          response: file.response,
          name: file.name,
          type: "image/jpeg",
          thumbUrl: "https://firebasestorage.googleapis.com/v0/b/"+app.options.storageBucket + "/o/HousePictures%2F" + houseID + "%2F" + file.uid + "_600x400?alt=media"
        });
      } else isReady = false;
    });
    if (isReady) {
      const convertedValues = {
        uploadImage: uploadImage,
        id: houseID,
        author: auth.currentUser.uid,
      };
      setDoc(doc(db, "houses", houseID), convertedValues, {
        merge: true,
      });
    }
  };

  const handleUpload = (uploadOptions) => {
    const { onSuccess, onError, file, onProgress } = uploadOptions;
    const storageRef = ref(
      storage,
      "HousePictures/" + houseID + "/" + file.uid
    );
    uploadBytes(storageRef, file).then(() => {
      getDownloadURL(storageRef).then(async (url) => {
        onSuccess(url);
        
      });
    });


  };
  const handleRemove = (file) => {
    const imageRef = ref(storage, "HousePictures/" + houseID + "/" + file.uid);
    const thumbRef = ref(storage, "HousePictures/" + houseID + "/" + file.uid + "_600x400");
    deleteObject(imageRef);
    deleteObject(thumbRef);
  };
  const checkKeyDown = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };
  const uploadButton = (
    <button
      className="uploadButton"
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const handleChangeDate = (date, dateString) => {
    setProcessionDate(dateString);
  }

  const handleSuspend = () => {
    setPostStatus(0);
  }
  const handleDelete = () => {
    setPostStatus(2);
    setDoc(doc(db, "houses", houseID), {postStatus: 2}, {
      merge: true,
    }).then(()=> {
      message.success("Delete successfully!");
      BackHome();
    });

  }

  const handlePost = () => {
    setPostStatus(1);
  }

  return (
    <div className="container">

      <Form
        {...formItemLayout}
        variant="filled"
        className="form"
        onKeyDown={checkKeyDown}
        layout="vertical"
        onFinish={onFinish}
        form={form}
        validateMessages={{ required: "" }}
      >

        <Form.Item
          label="Upload Image"
          name="uploadImage"
          className="singleLine"
        >
          <Upload
            customRequest={handleUpload}
            listType="picture-card"
            multiple={true}
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onRemove={handleRemove}
          >
          {uploadButton}
          </Upload>
        </Form.Item>

        {/* 展示图片的对话框，点击图片上面的小眼睛就会出现这个 */}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal>
        <div className="firstContainer">
          <div className="addressContainer">
            <label className="label">Property Address</label>
            <input required className="addressInput" ref={inputRef} />
          </div>
          <div className="dateContainer">
            <label className="dateLabel">Procession Date</label>
            <DatePicker onChange={handleChangeDate} value={dayjs(processionDate)} />
          </div>
        </div>
        <Form.Item
          label="Unit#"
          name="unit"
          className="formItem"
          tooltip={{
            title: "Unit number of address",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Property type"
          name="propertyType"
          className="formItem"
        >
          <Select
            options={[
              {
                label: "Apartment",
                value: "Apartment",
              },
              {
                label: "House",
                value: "House",
              },
              {
                label: "Townhouse",
                value: "Townhouse",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Homes for sale or rent"
          name="homeState"
          className="formItem"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: "For Sale",
                value: "For Sale",
              },
              {
                label: "For Rent",
                value: "For Rent",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Maintenance Fee"
          name="maintenanceFee"
          className="formItem"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Property Tax"
          name="tax"
          className="formItem"
        >
          <Input />
        </Form.Item>
       

       

        <Form.Item
          label="Number of Bedrooms"
          name="bedrooms"
          className="formItem"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Number of Bathrooms"
          name="bathrooms"
          className="formItem"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Garage Parking"
          name="garageParking"
          className="formItem"
          tooltip={{
            title: "It will show on the front page ",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Outside Parking"
          name="outsideParking"
          className="formItem"
          tooltip={{
            title: "It will show on the front page as Garage parking + Outside Parking",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Keep property address private"
          name="keepPrivate"
          tooltip={{
            title: "Tooltip with customize icon",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch defaultChecked />
        </Form.Item> */}


        <div className="singleLine">Lot Size :</div>
        <div className="formItem lotSizeContainer">

          <Form.Item
            label="width"
            name="lotWidth"
            className="lotSizeItem"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="length"
            name="lotLength"
            className="lotSizeItem"
          >
            <Input />
          </Form.Item>

        </div>
        <Form.Item
          label="Square feet"
          name="squareFeet"
          className="formItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          className="formItem"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Laundry type"
          name="laundryType"
          className="formItem"
        >
          <Select
            options={[
              {
                label: "In Suite",
                value: "In Suite",
              },
              {
                label: "In Building",
                value: "In Building",
              },
              {
                label: "Available at Cost",
                value: "Available At Cost",
              },
              {
                label: "None",
                value: "None",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Air condition type"
          name="airConditionType"
          className="formItem"
        >
          <Select
            options={[
              {
                label: "Central AC",
                value: "Central AC",
              },
              {
                label: "AC Available",
                value: "AC Available",
              },
              {
                label: "None",
                value: "None",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Heating type"
          name="heatingType"
          className="formItem"
        >
          <Select
            options={[
              {
                label: "Central Heating",
                value: "central Heating",
              },
              {
                label: "Electric Heating",
                value: "electric Heating",
              },
              {
                label: "Gas Heating",
                value: "Gas Heating",
              },
              {
                label: "Radiator Heating",
                value: "Radiator Heating",
              },
              {
                label: "Heating Available",
                value: "Heating Available",
              },
              {
                label: "None",
                value: "None",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Portion of Property"
          name="portion_lease"
          className="portionOfProperty"
        >

          <Checkbox.Group className="checkBoxGroup" options={propertyOptions} />
        </Form.Item>
        <Form.Item
          label="Other Information About This Property"
          name="portion_lease_other"
          className="portionOfProperty"
          tooltip={{
            title: "Please input other items if not listing above, separate by comma",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input className="portionInput" />
        </Form.Item>
        <Form.Item
          className="saveButton">
          <Button htmlType="submit" className="marginRight" onClick={handleSuspend}>Save/Suspend</Button>
          <Button htmlType="submit" className="marginRight" onClick={handlePost}>Post </Button>
          <Popconfirm
            title="Delete this house post"
            description="Once deleted, all data cannot be restored."
            onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button className="marginRight" danger>Delete</Button>
          </Popconfirm>
        </Form.Item>
      </Form>

    </div>
  );
};

export default Uploader;

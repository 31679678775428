import { getAuth, onAuthStateChanged } from "@firebase/auth";
import Login from "./Login";
import Register from "./Register";
import PersonalPage from "./PersonalPage";
import { useSelector } from "react-redux";

const UserConsole = () => {
  let page = useSelector((state) => state.userConsole.value);
  return (
    <div>
      {page === "login" && <Login />}
      {page === "profile" && <PersonalPage />}
      {page === "register" && <Register />}
      <div
        style={{
          color: "gray",
          cursor: "pointer",
          transition: "color 0.3s",
        }}
      ></div>
    </div>
  );
};
export default UserConsole;

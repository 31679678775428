// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAFt7HTs8Zlocw4cI1ZW7SA3a7OK9Xth20",
  authDomain: "realtorkiller.firebaseapp.com",
  projectId: "realtorkiller",
  storageBucket: "realtorkiller.appspot.com",
  messagingSenderId: "587608823028",
  appId: "1:587608823028:web:c5f8dee9c4625574cdbaa1",
  measurementId: "G-7DP258LW1D",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyBbtQnG_rRZqwhWXFIX9CToyThnEm48Vgk",
  authDomain: "homehitches-2427c.firebaseapp.com",
  projectId: "homehitches-2427c",
  storageBucket: "homehitches-2427c.appspot.com",
  messagingSenderId: "69109860950",
  appId: "1:69109860950:web:3a7ac63f0cd53fd6cac9f7",
  measurementId: "G-R687X2D7VS"
};

const apiKey = "AIzaSyAFt7HTs8Zlocw4cI1ZW7SA3a7OK9Xth20";

// Initialize Firebase
const app = initializeApp(firebaseConfigProd);
// const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
export { app };
export { db };
export {apiKey};

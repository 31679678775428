import { memo } from "react";
import React from 'react';
import { Tabs } from 'antd';
import "@/css/global.scss";
import { SellerDetails, BuyerDetails, LandlordDetails, TenantDetails } from "./content";

const onChange = (key) => {
  //console.log(key);
};
const items = [
  {
    key: '1',
    label: 'Seller',
    children: <SellerDetails/>
  },
  {
    key: '2',
    label: 'Buyer',
    children: <BuyerDetails/>
  },
  {
    key: '3',
    label: 'Landlord',
    children: <LandlordDetails/>
  },
  {
    key: '4',
    label: 'Tenant',
    children: <TenantDetails/>,
  },
]

const Instruction = () => {
  return (
    // <div className="contentContainer">
    <Tabs className="contentContainer" defaultActiveKey="1" items={items} onChange={onChange}  tabPosition='left' centered='true'/>
    // </div>
  );
};

export default memo(Instruction);

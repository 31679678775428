import { Form, Image, Button } from "antd";
import Text from "@/components/Text";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase/configs";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useParams, Link } from "react-router-dom";
import { Space, Tag } from "antd";
import "./index.scss";
import { useSelector } from "react-redux";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

const CondoShower = () => {
  let { houseID } = useParams();
  const whetherLogin = useSelector((state) => state.userConsole.value);
  const [houseData, setHouseData] = useState(null);
  const [form] = useForm();
  const [imageArray, setImageArray] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [imgNo, setImgNo] = useState(0);
  const [owner, setOwner] = useState({});
  const getHouseData = async (houseID) => {
    const houseRef = doc(db, "houses", houseID);
    const houseSnap = await getDoc(houseRef);
    let houseDataHolder = houseSnap.data();

    if (houseDataHolder.portion_lease_other) {
      houseDataHolder.portion_lease = houseDataHolder.portion_lease.concat(
        houseDataHolder.portion_lease_other.split(",")
      );
    }
    const userRef = doc(db, "users", houseDataHolder.author)
    const ownerHolder = await getDoc(userRef);
    setOwner(ownerHolder.data());
    form.setFieldsValue(houseDataHolder);
    setHouseData(houseDataHolder);
    setImageArray(houseDataHolder.uploadImage);
  };

  const onClickShowAll = () => {
    setModalOpen(true);
  };
  const onClickLeft = () => {
    setImgNo(imgNo - 1);
  };
  const onClickRight = () => {
    setImgNo(imgNo + 1);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    getHouseData(houseID);
  }, []);

  return (
    <div>
      {modalOpen && (
        <div className="modal">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="allImageContainer">
            {imageArray.map((item, index) => (
              <div className="showAllImages">
                <Image
                  key={index + 'displayimage'}
                  id={`img${index}`}
                  src={item.response}
                  alt={`Modal-img${index}`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="form-container">
        <div className="imagesContainer">
          <div className="image1">
            {imageArray.length > 0 &&
              imageArray[0] &&
              imageArray[0].response && (
                <Image key={"image_1"} src={imageArray[0].response} />
              )}
          </div>
          <div className="column2">
            <div className="image2">
              {imageArray.length >= 2 &&
                imageArray[1] &&
                imageArray[1].response && (
                  <Image key={"image_2"} src={imageArray[1].response} />
                )}
            </div>
            <div className="image2">
              {imageArray.length >= 3 &&
                imageArray[2] &&
                imageArray[2].response && (
                  <Image key={"image_3"} src={imageArray[2].response} />
                )}
            </div>
          </div>
          <div className="column3">
            <div className="image3">
              {imageArray.length >= 4 &&
                imageArray[3] &&
                imageArray[3].response && (
                  <Image key={"image_4"} src={imageArray[3].response} />
                )}
            </div>
            <div className="image3">
              {imageArray.length >= 5 &&
                imageArray[4] &&
                imageArray[4].response && (
                  <div>
                    <Image key={"image_5"} src={imageArray[4].response} />
                    <Button className="showAll" onClick={onClickShowAll}>
                      Show all
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>

        <Form
          {...formItemLayout}
          variant="filled"
          className="form"
          layout="vertical"
          form={form}
        >
          <Form.Item label="Property address" className="formItem4">
            <Text text={houseData?.address} />
          </Form.Item>
          <Form.Item label="Procession Date" className="formItem3">
            <Text text={houseData?.processionDate} />
          </Form.Item>
          <Form.Item label="Unit#" className="formItem">
            <Text text={form.getFieldValue("unit")} />
          </Form.Item>
          <Form.Item label="Property type" className="formItem">
            <Text text={form.getFieldValue("propertyType")} />
          </Form.Item>
          <Form.Item label="Homes for sale or rent" className="formItem">
            <Text text={form.getFieldValue("homeState")} />
          </Form.Item>
          <Form.Item label="Maintenance Fee" className="formItem">
            <Text text={form.getFieldValue("maintenanceFee")} />
          </Form.Item>
          <Form.Item label="Property Tax" className="formItem">
            <Text text={form.getFieldValue("tax")} />
          </Form.Item>
          <Form.Item label="Number of Bedrooms" className="formItem">
            <Text text={form.getFieldValue("bedrooms")} />
          </Form.Item>

          <Form.Item label="Number of Bathrooms" className="formItem">
            <Text text={form.getFieldValue("bathrooms")} />
          </Form.Item>
          <Form.Item label="Garage Parking" className="formItem">
            <Text text={form.getFieldValue("garageParking")} />
          </Form.Item>
          <Form.Item label="Outside Parking" className="formItem">
            <Text text={form.getFieldValue("outsideParking")} />
          </Form.Item>
          <div className="formItem2">Lot Size :</div>
          <div className="formItem lotSizeContainer">
            <Form.Item label="width" className="lotSizeItem">
              <Text text={form.getFieldValue("lotWidth")} />
            </Form.Item>
            <Form.Item label="length" className="lotSizeItem">
              <Text text={form.getFieldValue("lotLength")} />
            </Form.Item>
          </div>
          <Form.Item label="Square feet" className="formItem">
            <Text text={form.getFieldValue("squareFeet")} />
          </Form.Item>
          <Form.Item label="Price" className="formItem">
            <Text text={form.getFieldValue("price")} />
          </Form.Item>

          <Form.Item label="Laundry type" className="formItem">
            <Text text={form.getFieldValue("laundryType")} />
          </Form.Item>

          <Form.Item label="Air condition type" className="formItem">
            <Text text={form.getFieldValue("airConditionType")} />
          </Form.Item>
          <Form.Item label="Heating type" className="formItem">
            <Text text={form.getFieldValue("heatingType")} />
          </Form.Item>

          {houseData?.portion_lease != undefined && <Form.Item
            label="Portion of Properties"
            className="formItem2"
          >
            <Space size={[0, 8]} wrap>
              {houseData?.portion_lease?.map((item, index) => (
                <Tag className="tag-item" key={"tag" + index}>
                  {item}{" "}
                </Tag>
              ))}
            </Space>
          </Form.Item>}
          {whetherLogin === "profile" && <Form.Item label="Contact Owner Directly" className="formItem2">
            <div >Email:<span onClick={(e) => { window.location.href = 'mailto:' + owner.email; }} className="link"> {owner.email} </span>  {owner.phone ? <span className="paddingRight">Phone: {owner.phone}</span> : ''}  {owner.others ? <span>    Other Contact: {owner.others}</span> : ''} </div>

          </Form.Item>}
          {whetherLogin !== "profile" && <Link to="/user" className="red">Sign In to View Contacts Details</Link>}
        </Form>
      </div>
    </div>
  );
};

export default CondoShower;

import styled from "styled-components";

export const HLeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  .logo {
    cursor: pointer;
    width: 20vw;
    max-width: 200px;
  }
  .logoImg {
    width: 100%;
  }
`;

export const footerMsg = [
  {
    list: [
      { name: "Youtube", url: "https://www.youtube.com/channel/UCPf804neg80PvGONN3VmgKg" },
      { name: "Instagram", url: "https://www.instagram.com/homehitches" },
    ],
  },
  {
    list: [
      { name: "Facebook", url: " https://www.facebook.com/profile.php?id=61558069707461" },
      { name: "Twitter", url: "https://twitter.com/HomeHitches" },
    ],
  },
  {
    list: [
      { name: "About us", url: "/aboutUs" },
      { name: "homehitches@gmail.com", url: "mailto:homehitches@gmail.com" },
    ],
  },
];

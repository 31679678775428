import "./index.scss";

const SellerSteps = () => {
    return (
        <div>
            <div className="boldLine">Preparation and Valuation</div>
            <div className="regularLine">(Including appraisal, repairs, cleaning, staging, and professional photography.)</div>
            <div className="boldLine">Legal Preparation</div>
            <div className="regularLine">(Engage a lawyer early for crucial tasks)</div>
            <div className="boldLine">Listing and Marketing</div>
            <div className="regularLine">(List your property on HomeHitches)</div>
            <div className="boldLine">Showings, Offers, and Negotiations</div>
            <div className="regularLine">(Ready for showing requests and up coming offers)</div>
            <div className="boldLine"> Closing the Sale</div>
            <div className="regularLine">(Finalize the sale with your lawyer)</div>

        </div>
    );
}
const BuyerSteps = () => {
    return(
        <div>
            <div className="boldLine">Financial Preparation</div>
            <div className="regularLine">(Obtain financing and budget for extra costs)</div>
            <div className="boldLine">Property Search and Evaluation</div>
            <div className="regularLine">(Define your needs, wants, and location using HomeHitches)</div>
            <div className="boldLine">Legal Preparation</div>
            <div className="regularLine">(Engage a lawyer early for crucial tasks)</div>
            <div className="boldLine">Making an Offer</div>
            <div className="regularLine">(Make an offer independently or through a lawyer)</div>
            <div className="boldLine"> Home Inspection and Due Diligence</div>
            <div className="regularLine">(Arrange a home inspection and review all documents)</div>
            <div className="boldLine"> Closing the Transaction</div>
            <div className="regularLine"> (Ready for closing and budget for closing costs)</div>
        </div>
    );  
}
const LandlordSteps = () => {
    return(
        <div>
            <div className="boldLine">Preparation and Letting Readiness:</div>
            <div className="regularLine">Evaluate, repair, clean, photograph the rental, and liaise with tenants for readiness.</div>
            <div className="boldLine">Legal and Regulatory Preparation:</div>
            <div className="regularLine">Comply early with local rental laws for a seamless letting process.</div>
            <div className="boldLine">Listing and Marketing on HomeHitches</div>
            <div className="regularLine">List your property on HomeHitches to attract potential tenants.</div>
            <div className="boldLine">Showings, Applications, and Lease Negotiations</div>
            <div className="regularLine">Prepare for viewings and efficiently handle applications.</div>
            <div className="boldLine"> Finalizing the Lease:</div>
            <div className="regularLine">Finalize the lease independently or with a lawyer's help.</div>
        </div>
    );  
}
const TenantSteps = () => {
    return(
        <div>
            <div className="boldLine">Preparation and Letting Readiness:</div>
            <div className="regularLine">Set up your rental budget, location and the move in date.</div>
            <div className="boldLine">Legal and Regulatory Preparation:</div>
            <div className="regularLine">Comply early with local rental laws for a seamless letting proces.</div>
            <div className="boldLine">Contact with the Landlord on HomeHitches</div>
            <div className="regularLine">Efficiently reach potential landlords via HomeHitches.</div>
            <div className="boldLine">Showings, Applications, and Lease Negotiations</div>
            <div className="regularLine">Book viewings and prepare to make an offer.</div>
            <div className="boldLine"> Finalizing the Lease:</div>
            <div className="regularLine">Finalize the lease independently or with a lawyer's help.</div>
        </div>
    );  

}
export { SellerSteps, BuyerSteps, LandlordSteps, TenantSteps }
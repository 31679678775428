import { createSlice } from '@reduxjs/toolkit'


export const getSearch = createSlice({
    name: 'search',
    initialState: {
        value: 'clear'
    },
    reducers: {
        search: (state, action) => {
            state.value = "search";
            state.text = action.payload;
        },
        clear: (state) => {
            state.value = "clear";
            state.text = "";
        }
    }
})

// Action creators are generated for each case reducer function
export const { search, clear } = getSearch.actions

export default getSearch.reducer

// const getHouseDataStarted = () => ({
//     type: 'houseDate/fetchStarted'
// })
// const getHouseDataSuccess = HouseDate => ({
//     type: 'houseDate/fetchSucceeded',
//     payload: HouseDate
// })
// const getHouseDataFailed = error => ({
//     type: 'houseDate/fetchFailed',
//     error
// })

// const searchText = (text,state) => async dispatch => {
//     dispatch(getHouseDataStarted())
//     try {
//         const houseRef = collection(db, "houses");
//         let rentHouseQuery = query(houseRef, where("homeState", "==", state));
//         if(text) {
//             rentHouseQuery = query(houseRef, where("homeState", "==", state), where(""));
//         }
//         const rentHouseSnap = await getDocs(rentHouseQuery);
//         const houseData = rentHouseSnap.docs.map((house) => {
//             if (house.data().address) return house.data();
//         })
//         dispatch(getHouseDataSuccess(houseData));
//     } catch (err) {
//         dispatch(getHouseDataFailed(err.toString()));
//     }
// }

// export const displayResult = (houseData) => {
// }


// export const getHouseData = async () => {
//     const houseRef = collection(db, "houses");
//     let saleHouseQuery = query(houseRef, where("homeState", "==", "For Sale"));

//     onSnapshot(
//         saleHouseQuery,
//         (saleHouseSnapShot) => {
//             let saleHouseArrayHolder = [];
//             saleHouseSnapShot.forEach((house) => {
//                 if (house.data().address) {
//                     saleHouseArrayHolder.push(house.data());
//                     setSaleHouseArray(saleHouseArrayHolder);
//                 }
//             });
//         },
//         (error) => {
//             console.log(error);
//         }
//     );
//     let rentHouseQuery = query(houseRef, where("homeState", "==", "For Rent"));
//     const rentHouseSnap = await getDocs(rentHouseQuery);

//     setRentHouseArray(
//         rentHouseSnap.docs.map((house) => {
//             if (house.data().address) return house.data();
//         })
//     );
// };
import { createSlice } from '@reduxjs/toolkit'

export const userConsolePage = createSlice({
  name: 'page',
  initialState: {
    value: 'login'
  },
  reducers: {
    login: state => {
      state.value = "login";
    },
    register: state => {
      state.value = "register";
    },
    profile: (state, action) => {
      state.value = "profile";
      state.uid = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { login, register, profile } = userConsolePage.actions

export default userConsolePage.reducer
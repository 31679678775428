import { createSlice } from "@reduxjs/toolkit";

export const houseMessage = createSlice({
  name: "houseMessage",
  initialState: {
    houseState: "For Rent",
  },
  reducers: {
    toForSale: (state) => {
      state.houseState = "For Sale";
    },
    toForRent: (state) => {
      state.houseState = "For Rent";
    },
  },
});

export const { toForSale, toForRent } = houseMessage.actions;

export default houseMessage.reducer;
